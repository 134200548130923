import React, { useEffect } from 'react';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import { Element, scroller } from 'react-scroll';
import { graphql, useStaticQuery } from 'gatsby';
import { useQueryParam, StringParam } from 'use-query-params';

import styles from './styles';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const infographicsQuery = graphql`
  query InfographicsQuery {
    content: allStrapiCampanas(sort: { order: ASC, fields: updated_at }) {
      edges {
        node {
            id
            campaignName
            mediafiles {
                name
                  localFile {
                    childImageSharp {
                      fluid {
                        sizes
                        src
                        srcSet
                        srcSetWebp
                      }
                    }
                  }
            }
        }
      }
    }
  }
`;

const settings = (items) => ({
    arrows: false,
    dots: true,
    speed: 3000,
    infinite: items.length > 3,
    draggable: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 1025,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                speed: 3000,
                dots: true,
                draggable: false,
            }
        },
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                speed: 500,
                dots: false,
                draggable: true,
            }
        },
        {
            breakpoint: 651,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 500,
                dots: false,
                draggable: true,
            }
        }
    ]
});

const InfoCmp = () => {
    const [name] = useQueryParam('name', StringParam);
    const { content } = useStaticQuery(infographicsQuery);
    const { edges } = content;
    const campaignImages = (mediafiles) => mediafiles.map((mf, i) => (
        <img
            className="campaign-image"
            src={mf.localFile.childImageSharp.fluid.src}
            alt={mf.name}
            key={`mf-${mf.name}-${i}`}
        />
    ));

    useEffect(() => {
        if (name) scroller.scrollTo(name, { delay: 1500, smooth: true, offset: -30 });
    }, [name]);

    return (
        <section css={styles}>
            {edges.map((edge, index) => {
                const n = edge.node.campaignName.split(' ').join('-').toLowerCase();
                return (
                    <Element
                        name={n}
                        key={edge.node.id}
                        className={index % 2 ? 'campaign' : 'campaign campaign-odd'}
                    >
                        <h4>{edge.node.campaignName}</h4>
                        <Slider {...settings(edge.node.mediafiles)}>
                            {campaignImages(edge.node.mediafiles)}
                        </Slider>
                    </Element>
                );
            })}
        </section>
    );
};

export default InfoCmp;
