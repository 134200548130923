import React from 'react';
import { Helmet } from 'react-helmet';

import Header from '../components/common/Header';
import Footer from '../components/home/MainFooter';
import SubHeader from '../components/common/SubHeader';

import InfoCmp from '../components/infographics';

const Infographics = () => (
    <>
        <Helmet>
            <title>Comisión de Energía del CCE | Infografías</title>
            <meta name="description" content="Descubre la información que la Comisión de Energía brinda al público" />
        </Helmet>
        <main style={{ height: '100vh' }}>
            <Header section="infographics" />
            <SubHeader title="Infografías" />
            <InfoCmp />
            <Footer />
        </main>
    </>
);

export default Infographics;
