import { css } from '@emotion/core';

export default css({
    width: '100%',
    '& .campaign': {
        width: '100%',
        padding: '50px 0px',
        background: '#fff',
        '& > h4': {
            fontSize: 22,
            color: '#2f555e',
            textAlign: 'center',
            textTransform: 'uppercase',
            marginBottom: 40,
        },
        '& .slick-slider': {
            maxWidth: '1366px',
            margin: '0px auto',
            background: 'transparent',
        },
        '&-image': {
            width: '350px!important',
            borderRadius: 10,
            display: 'block!important',
            margin: '0px auto'
        }
    },
    '& .campaign-odd': {
        background: '#f4f7f8',
    },
    '@media only screen and (min-width: 768px)': {
        '& .members-background': {
            flexDirection: 'row',
            '& .members-title': {
                marginRight: 85,
                width: '30%',
                '& > h3': {
                    fontSize: 36,
                    textAlign: 'left',
                },
                '& > a': {
                    display: 'block',
                }
            },
            '& .slick-slider': {
                width: '44%',
                margin: 0,
            }
        }
    }
});
